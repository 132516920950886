<template>
  <div class="orderPayBody" v-loading="loadDate">
    <div class="title">
      <div class="name">
        <svg class="icon" aria-hidden="true"><use href="#icon-pay"></use></svg>
        <!-- 订单付款 -->
        <span>{{ $t("label.partnerCloud.orderingPlatform.payment") }}</span>
      </div>
    </div>
    <div class="body">
      <div class="bodyDetail">
        <!-- 订单描述 -->
        <div class="mainDesc">
          <div class="logo">
            <svg v-if="comeWay" class="Secicon" aria-hidden="true">
              <use href="#icon-checkMark"></use>
            </svg>
            <div v-else class="el-icon-warning Secicon"></div>
          </div>
          <span class="maintitle">{{
            comeWay ? $t('c67') : $t('c68')
          }}</span>
          <div class="payMoney">
            <span class="moneyTitle">{{$t('c69')}}：</span>
            <span class="money"
              >{{ this.$store.state.userInfoObj.currencyCode
              }}{{ this.totalamount }}</span
            >
          </div>
        </div>
        <!-- 订单信息 -->
        <div class="orderModule">
          <div class="orderTitle">
            <span class="blueLine"></span>
            <span class="orderInfoTitle">{{$t('c70')}}</span>
          </div>
          <div class="orderInfo">
            <div
              class="orderInfoItem"
              v-for="(item, index) in orderInfo"
              :key="index"
            >
              <span class="itemField">{{ item.title }}:</span>
              <span class="itemValue">{{ item.value }}</span>
            </div>
          </div>
        </div>
        <!-- 支付方式 -->
        <div class="orderModule">
          <div class="orderTitle">
            <span class="blueLine"></span>
            <span class="orderInfoTitle">{{$t('c71')}}</span>
          </div>
          <div class="orderInfo" style="margin-left: 38px; margin-top: 0px">
            <el-menu
              :default-active="activeIndex"
              class="el-menu-demo"
              mode="horizontal"
              @select="handleSelect"
              style="margin-bottom: 25px"
            >
              <el-menu-item index="1" v-show="showAccountPay">
                {{
                  $t("label.partnerCloud.orderingPlatform.toPay")
                }}</el-menu-item
              >
              <el-menu-item index="2" v-show="showOnlinePay && $store.state.currencyCode == 'CNY'">
                {{$t('c72')}}
              </el-menu-item>
            </el-menu>
            <div v-show="activeIndex === '1' && showAccountPay">
              <div class="accountPosition">
                <div v-show="accountShow" style="margin-right: 55px">
                  <el-radio v-model="accountPayMethod" label="account">
                    <div class="accountpay">
                      <svg class="icon" aria-hidden="true">
                        <use href="#icon-zhanghuzhifu"></use>
                      </svg>
                      <span>{{
                        $t("label.partnerCloud.orderingPlatform.toPay")
                      }}</span>
                    </div>
                  </el-radio>
                  <div class="availdPosition">
                    {{$t('c73')}}：{{ this.$store.state.currencyCode }}{{ account }}
                  </div>
                  <!-- <div v-show="accountEnough" class="availdPosition">
                    当前额度不足，请<span
                      style="color: #2d6cfc; cursor: pointer"
                      @click="openCharge"
                      >点击此处充值</span
                    >
                  </div> -->
                  <div v-show="accountEnough" class="availdPosition"><span
                      style="color: #2d6cfc; cursor: pointer"
                      @click="openCharge"
                      >{{$t('label.partnerCloud.orderingPlatform.rechargeIt')}}</span
                    >
                  </div>
                </div>
                <div v-show="rebateShow">
                  <el-radio v-model="accountPayMethod" label="rebate">
                    <div class="accountpay">
                      <svg class="icon" aria-hidden="true">
                        <use href="#icon-fanlizhifu"></use>
                      </svg>
                      <span>{{$t('c74')}}</span>
                    </div>
                  </el-radio>
                  <div class="availdPosition">
                    {{$t('c75')}}：{{ this.$store.state.currencyCode }}{{ rebate }}
                  </div>
                </div>
              </div>
            </div>
            <div
              v-show="activeIndex === '2' && showOnlinePay"
              class="onlinePay"
            >
              <el-radio v-model="onlinePayMethod" label="weixin">
                <div class="accountpay" style="padding: 9px 21px">
                  <svg class="weixinicon" aria-hidden="true">
                    <use href="#icon-weixinzhifu"></use>
                  </svg>
                </div>
              </el-radio>
            </div>
          </div>
        </div>
        <!-- 按钮组 -->
        <div class="btnGroup">
          <el-button
            @click="gotoPay"
            class="iconButton"
            type="primary"
            icon="el-icon-bank-card"
            >{{$t('c76')}}</el-button
          >
          <el-button @click="cancelPay" class="secButton">{{
            $t("label.tabpage.cancel")
          }}</el-button>
        </div>
      </div>
    </div>
    <!-- 支付 -->
    <recharge-process
      @paySuccess="paySuccess"
      @rechargeCancle="rechargeCancle"
      :showDialog="showDialogTwo"
      :rechargeDialog="rechargeDialog"
      :dialogTitle="dialogTitle"
      :firItemTitle="firItemTitle"
      :secItemTitle="secItemTitle"
      :paySuccess="paySucess"
      :payMoney="payCount"
      :payFail="payFail"
      :payAgain="payAgain"
      :formValue="payMoney"
      :isReadonly="isReadonly"
      :dataOrign="dataOrign"
      :rechargeStatus="status"
    ></recharge-process>
    <!-- 充值 -->
    <recharge-process
      :showDialog="showDialog"
      @rechargeCancle="rechargeCancleSecond"
      :rechargeDialog="rechargeDialogSecond"
      @paySuccess="paySuccessTwo"
    ></recharge-process>
  </div>
</template>

<script>
import {
  getPayMethod,
  doUnifiedOrder,
  queryOrders,
} from "./api.js";
import rechargeProcess from "@/views/partnerAccount/components/rechargeProcess.vue";
export default {
  components: {
    rechargeProcess,
  },
  data() {
    return {
      totalamount:'',
      loadDate: false,
      comeWay: true,
      orderInfo: [
        { id: "number", title: this.$i18n.t('c78'), value: "" },
        // { id: "date", title: this.$i18n.t('c79'), value: "" },
        { id: "address", title: this.$i18n.t("label.mailinginfo"), value: "" },
        { id: "orderAddress", title: this.$i18n.t('c80'), value: "" },
      ],
      activeIndex: "1",
      accountPayMethod: "account",
      onlinePayMethod: "weixin",
      account: 0,
      rebate: 0,
      orderPayMethod: {},
      showAccountPay: true,
      showOnlinePay: false,
      accountShow: true,
      rebateShow: true,
      accountEnough: true,
      showDialog: false,
      rechargeDialog: false, //支付弹框显示与隐藏
      showDialogTwo: false,
      rechargeDialogSecond: false,
      dialogTitle: this.$i18n.t(
        "label.partnerCloud.orderingPlatform.orderPayment"
      ), //订单支付
      firItemTitle: this.$i18n.t(
        "label.partnerCloud.orderingPlatform.paymentAmount"
      ), //支付金额
      secItemTitle: this.$i18n.t(
        "label.partnerCloud.orderingPlatform.paymentMethod"
      ), //支付方式
      paySucess: this.$i18n.t("label.partnerCloud.paySuccess"), //支付成功
      payCount: this.$i18n.t("label.partnerCloud.payTheAmount"), //支付金额
      payFail: this.$i18n.t("label.partnerCloud.payForFailure"),
      payAgain: false,
      payMoney: this.totalamount,
      isReadonly: true,
      dataOrign: {},
      status: "qr",
    };
  },
  mounted() {
    document.title = this.$setTitle(this.$i18n.t("label.ordering.platform"));
    this.getOrderInfo();
    this.loadDate = true;
    this.getPayMethods();
  },
  methods: {
    // 取消支付
    cancelPay() {
      this.goToDetail();
    },
    // 跳转详情页
    goToDetail() {
      this.$router.push(
        `/commonObjects/detail/${this.$route.params.orderId}/DETAIL`
      );
    },
    // 点击此处充值
    openCharge() {
      if(this.$store.state.userInfoObj.currencyCode==='CNY'){        
        this.showDialog = true;
        this.rechargeDialogSecond = true;
      }else{
        // c529
        this.$message.warning('暂无可用充值方式，请联系管理员')
      }
    },
    //  支付成功
    paySuccess() {
      this.$message.success(this.$i18n.t("label.partnerCloud.paySuccess")); //支付成功
      this.goToDetail();
    },
    paySuccessTwo() {
      this.loadDate = true;
      this.getPayMethods();
    },
    //弹框关闭方法
    rechargeCancle() {
      this.rechargeDialog = false;
    },
    rechargeCancleSecond() {
      this.rechargeDialogSecond = false;
    },
    //   支付
    async gotoPay() {
      // 账户支付
      let obj = {};
      if (this.activeIndex === "1") {
        // 账户支付
        if (this.accountPayMethod === "account") {
          // 可用额度>待回款金额
          if (Number(this.account) >= Number(this.totalamount)) {
            this.loadDate = true;
            obj = {
              money: this.totalamount,
              paymentMethod: "",
              payway: "AccountPayment",
              isrebate: "false",
              rebatemoney: "",
              orderid: this.$route.params.orderId,
              url:window.location.origin
            };
            let result = await doUnifiedOrder(obj);
            this.loadDate = false;
            if (result.result) {
              this.$message.success(
                this.$i18n.t("label.partnerCloud.paySuccess")
              ); //支付成功
              this.goToDetail();
            }
          } else {
            this.rechargeDialogSecond = true;
            this.showDialog = true;
          }
        } else if (this.accountPayMethod === "rebate") {
          // 可用额度>待回款金额
          if (Number(this.rebate) >= Number(this.totalamount)) {
            obj = {
              money: 0,
              paymentMethod: "",
              payway: "",
              isrebate: true,
              rebatemoney: this.totalamount,
              orderid: this.$route.params.orderId,
              url:window.location.origin
            };
            let result = await doUnifiedOrder(obj);
            this.loadDate = false;
            if (result.result) {
              this.$message.success(
                this.$i18n.t("label.partnerCloud.paySuccess")
              ); //支付成功
              this.goToDetail();
            }
          } else {
            this.$message.error("c520");
          }
        }
      } else if (
        this.activeIndex === "2" &&
        this.onlinePayMethod === "weixin"
      ) {
        obj = {
          money: this.totalamount,
          paymentMethod: "wxpay",
          payway: "OnlinePayment",
          isrebate: this.isRepaire,
          rebatemoney: this.rebatemoney,
          orderid: this.$route.params.orderId,
           url:window.location.origin
        };
        this.dataOrign = obj;
        this.rechargeDialog = true;
        this.showDialogTwo = true;
      }
    },
    // 获取支付方式
    async getPayMethods() {
      let result = await getPayMethod({});
      this.loadDate = false;
      this.orderPayMethod = result.data;
      this.showAccountPay =
        this.orderPayMethod.isUsingPartnerFund === "true" ||
        this.orderPayMethod.avaliable !== ""
          ? true
          : false;
      this.showOnlinePay =
        this.orderPayMethod.isOnlinePayment === "true" ? true : false;
      if (this.showAccountPay) {
        this.activeIndex = "1";
        this.accountShow =
          this.orderPayMethod.isUsingPartnerFund === "true" ? true : false;
        this.rebateShow = this.orderPayMethod.avaliable !== "" ? true : false;
        if (this.accountShow) {
          this.accountPayMethod = "account";
        } else {
          this.accountPayMethod = "rebate";
        }
      } else {
        this.activeIndex = "2";
      }
      this.rebate = result.data.avaliable;
      this.account =
        result.data.accountbalance && result.data.accountbalance !== ""
          ? result.data.accountbalance
          : 0;
      this.accountEnough =
        Number(this.totalamount) <
        Number(result.data.accountbalance)
          ? false
          : true;
    },
    // 获取订单信息
    async getOrderInfo() {
      let result = await queryOrders({ orderid: this.$route.params.orderId });
      this.orderInfo.forEach((item) => {
        if (item.id === "number") {
          item.value = result.data.name;
        } else if (item.id === "date") {
          item.value = result.data.date;
        } else if (item.id === "address") {
          item.value = result.data.shippingaddress;
        } else if (item.id === "orderAddress") {
          item.value = result.data.billingaddress;
        }
      });
      this.totalamount = result.data.totalamount
    },
    handleSelect(val) {
      this.activeIndex = val;
    },
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      // 多语言无需修改
      if (from.name === "详情页") {
        vm.comeWay = false;
      } else {
        vm.comeWay = true;
      }
    });
  },
};
</script>

<style lang='scss' scoped>
.orderPayBody {
  padding: 10px 20px;
  height: 100%;
  display: flex;
  flex-direction: column;
}
.title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  border-radius: 2px 2px 3px 3px;
  border: 1px solid #dddbda;
}
.name > span {
  margin-left: 10px;
}
.orderTitle {
  font-weight: 600;
  font-size: 18px;
}
.name > .icon {
  width: 32px;
  height: 32px;
}
.name {
  display: flex;
  align-items: center;
}
.body {
  margin-top: 10px;
  height: 100%;
  background: white;
  border: 1px solid #dddbda;
  padding-top: 44px;
  border-radius: 2px 2px 3px 3px;
  display: flex;
  justify-content: center;
  .bodyDetail {
    width: 75%;
    height: 100%;
    position: relative;
    .mainDesc {
      padding-left: 40px;
      display: flex;
      align-items: center;
      margin-bottom: 20px;
      .logo {
        .Secicon {
          width: 48px;
          height: 48px;
        }
        ::v-deep.el-icon-warning:before {
          font-size: 48px;
          color: #fe8a14;
        }
      }
      .maintitle {
        font-size: 18px;
        font-weight: bold;
        color: #080707;
        margin-left: 12px;
      }
      .payMoney {
        position: absolute;
        right: 0px;
        .moneyTitle {
          font-size: 14px;
          font-weight: 400;
          color: #000000;
        }
        .money {
          font-size: 20px;
          font-weight: bold;
          color: #f11b21;
        }
      }
    }
    .orderModule {
      margin-top: 24px;
      .orderTitle {
        width: 100%;
        padding-left: 38px;
        height: 40px;
        display: flex;
        align-items: center;
        background: #f3f7ff;
        .blueLine {
          display: inline-block;
          width: 4px;
          height: 18px;
          background: #2d6cfc;
        }
        .orderInfoTitle {
          font-size: 14px;
          font-weight: 400;
          color: #080707;
          margin-left: 8px;
        }
      }
      .orderInfo {
        padding: 0px 5px;
        margin-top: 24px;
        .weixinicon {
          display: inline-block;
          width: 93px;
          height: 28px;
        }
        .orderInfoItem {
          font-size: 14px;
          margin-bottom: 24px;
          .itemField {
            display: inline-block;
            width: 150px;
            text-align: right;
            color: #5f5e5e;
          }
          .itemValue {
            margin-left: 20px;
            color: #080707;
            font-weight: 400;
          }
        }
        .accountPosition {
          display: flex;
          .availdPosition {
            margin-top: 12px;
            font-size: 14px;
            font-weight: 400;
            color: #888888;
            margin-left: 25px;
          }
        }
        .accountpay {
          display: inline-block;
          padding: 15px 28px;
          border-radius: 4px 4px 4px 4px;
          border: 1px solid #e1e1e1;
          font-size: 14px;
          color: #080707;
          box-shadow: 0px 0px 8px 1px rgba(0, 0, 0, 0.15);
          span {
            margin-left: 8px;
          }
        }
      }
      .onlinePay {
        ::v-deep.el-radio {
          display: flex;
          align-items: center;
        }
      }
    }
    .btnGroup {
      margin-top: 45px;
      display: flex;
      .iconButton {
        display: flex;
        align-items: center;
      }
      ::v-deep.el-button [class*="el-icon-"] + span {
        margin-left: 12px;
      }
      ::v-deep.el-icon-bank-card:before {
        font-size: 16px;
      }
      ::v-deep.el-button + .el-button {
        margin-left: 16px;
      }
      ::v-deep.el-button {
        font-size: 12px;
        padding: 8px 12px;
      }
      ::v-deep.el-button--primary {
        background-color: #2d6cfc;
        border-color: #2d6cfc;
      }
      ::v-deep.secButton {
        color: #080707;
      }
    }
  }
}
::v-deep.el-menu.el-menu--horizontal {
  height: 44px;
}
::v-deep.el-menu--horizontal > .el-menu-item.is-active {
  height: 44px;
  border-color: #006dcd;
}
::v-deep.el-menu--horizontal > .el-menu-item {
  height: 44px;
  line-height: 44px;
}
</style>